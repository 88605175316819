import { useState, useEffect } from 'react';
import '../CSS/Testimonial.scss';
import Testimonial from './Testimonial';

const Testimonials = props => { 

    return (
        <div className="Testimonials mt-3 mb-3" id="testimonials">
        <Testimonial testimonial = {{description: `"Atlantic Booking Systems designed our website for our cottage rental business from start to finish.

        We highly recommend them as they pay attention to every detail, and take great pride in their work

        Atlantic Booking Systems has a way of taking any thought you may have to make things easier with your business going digital and programming it exactly to your needs which has saved lots of time with an increasing work load."`, name: "Peter & Melissa Siepierski", title: "Owners", company: "Lakeland Cottages", image: "Images/lakeland.png"}}/>
        </div>
    )
}

export default Testimonials;