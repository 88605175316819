import { useState, useEffect } from 'react';
import '../CSS/Testimonial.scss';


const Testimonial = props => { 

    return (
        <div className="Testimonial mt-3 mb-3">
            <div class = "d-flex flex-lg-row flex-column justify-content-center align-items-center position-relative">
                <div class = "col-9 col-lg-4 col-sm-9 col-xs-9 col-lg-4 col-xl-4 testimonial-img testimonial-section border border-dark">
                    <img src={props.testimonial.image} alt="Testimonial Image" className="testimonial-image img-fluid w-100 h-100"/>
                </div>
                <div class = "col-9 col-lg-6 col-xl-6 p-3 p-lg-4 testimonial-text testimonial-section">
                    <article class = "mb-4">
                        {props.testimonial.description}
                    </article>
                    <h5 className="testimonial-name">{props.testimonial.name}</h5>
                    <h6 className="testimonial-title">{props.testimonial.title}</h6>
                    <h6 className="testimonial-company">{props.testimonial.company}</h6>
                </div>
            </div>
        </div>
    )
}

export default Testimonial;