import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

import Header from './Components/Header';
import Features from './Components/Features';
import Seperator from './Components/Seperator';
import Testimonials from './Components/Testimonials';
import ContactForm from './Components/ContactForm';
import Footer from './Components/Footer';
import './App.scss';

function App() {
  return (
    <div className="App">
      <div class = "container-fluid p-0">
        <Header />
        <Seperator name="What we offer"/> 
        <Features />
        <Seperator name="Some of our satisfied customers"/>
        <Testimonials />
        <Seperator name="Like what you see? Get in touch!"/>
        <ContactForm />
        <Footer />
      </div>
    </div>
  );
}

export default App;
