import { useState, useEffect } from 'react';
import '../CSS/Footer.scss';


const Footer = props => { 

    return (
        <div className="Footer bg-primary p-5 p-lg-5">
            <div class = "d-flex flex-lg-row flex-column">
                <div class = "col-12 col-lg-4 mb-5">
                    <h2 className="footer-title">Atlantic Booking Systems</h2>
                    <article className="footer-text">The ultimate platform to transform the way you manage bookings and communicate with your guests.<br/><br/>
                    Own your reservations, streamline communication, and unlock the potential of direct bookings with Atlantic Booking Systems
                    </article>
                </div>
                <div class = "col-12 col-lg-4 empty">
                    
                </div>
                <div class = "col-12 col-lg-4 mb-5">
                    <h2 className="footer-title">Contact Us</h2>
                    <article className="footer-text"><strong>Email:</strong> <a href="mailto:info@atlanticbookingsolutions.com">info@atlanticbookingsolutions.com</a></article>
                </div>
            </div>
        </div>
    )
}

export default Footer;