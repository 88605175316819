import { useState, useEffect } from 'react';
import '../CSS/Header.scss';
import Wave from './Wave';
import Display from './Display';
import Navbar from './Navbar';

const Header = props => { 

    /*const [Skills, setSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState({});


    const _retrieveSkills = () => {
        setSkills(SkillsJson.skills);
    }
    
    const _showSkills = () => {
        return Skills.map(Skill => {
            return (
                <div className="row">
                    <div className="col-8 align-self-center skill w-100">
                        <div className="card shadow">
                            <button href="#" onClick={() => setSelectedSkill(Skill)} className="btn">
                                <div className="card-body">
                                    <h5 className="card-title">{Skill.name}</h5>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            )
        })
    }
    
    useEffect(() => {
        _retrieveSkills();
    }, []);*/

    return (
        <div className="Header-Container">
            <Navbar/>
            <div className="Header d-flex justify-content-center align-items-center">
                <div className="col-12 col-lg-6 col-xl-5 header-col">
                    <h2 id="cta-header">Booking Management Done <strong>YOUR</strong> Way</h2><br/>
                    <article>Stop relying on third parties for booking management.<br/> <br/>
                    Own your reservations, streamline communication, and unlock the potential of direct bookings with Atlantic Booking Systems</article>
                    <a className="btn btn-custom mt-5" href="#contact">Get Started</a>
                </div>
                <div class = "col-xl-5 col-lg-5 col-md-7 col-sm-5">
                    <Display/>
                </div>
            </div>
        </div>
    )
}

export default Header;