import { useState, useEffect } from 'react';
import '../CSS/Navbar.scss';
import Wave from './Wave';
import Display from './Display';

const Navbar = props => { 
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  
    return (
        <div className = "Navbar w-100">
            <nav className="navbar navbar-expand-lg text-white">
                <div className="navbar-brand-container">
                    <a className = "navbar-brand text-white ms-2">
                            <img src = "Images/Logo.png" alt = "Logo" id = "logo" className='d-inline-block align-center'/>&nbsp;&nbsp;
                    </a>
                    <span class = "navbar-text">Atlantic Booking Systems</span>
                </div>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={handleNavCollapse}>
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse w-100 justify-content-end me-5"`} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#features">Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#testimonials">Testimonials</a>
                        </li>
                        <li className="nav-item nav-button-container">
                            <a className="btn btn-custom nav-button" href="#contact">Book a Demo</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;