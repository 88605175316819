import { useState, useEffect } from 'react';
import '../CSS/Feature.scss';


const Feature = props => { 

    return (
        <div className="Feature mb-5">
            <div class = "row justify-content-center">
                <div className="icon-container mt-1">
                    <i className={props.feature.icon}></i>
                </div>
                <h5 className="feature-header text-center mt-2">{props.feature.name}</h5>
            </div>
            <div class = "row justify-content-center">
                <article className="text-center mt-3">
                    {props.feature.description}
                </article>
            </div>
        </div>
    )
}

export default Feature;