import { useState, useEffect } from 'react';
import '../CSS/Features.scss';
import Feature from './Feature';


const Features = props => { 

    return (
        <div className="Features" id="features">
            <div class = "d-flex justify-content-center flex-lg-row flex-column flex-wrap">
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "Direct Bookings", icon: "bi bi-book", description: `With our easy and elegant payment flow, last minute reservations have never been easier! 
                    By focusing on simplicity, security and user-centered design, our payment flow ensures that guests can complete their reservations with ease`}}/>
                </div>
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "Integrated Messaging System", icon: "bi bi-chat-dots", description: `When guests make a booking, our system will automatically 
                    generate and send them a secure link. This link serves as a direct and effortless channel for communication with you, ensuring that guests can reach out with any questions, requests, or concerns in a secure and convenient manner. `}}/>
                </div>
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "Guest Links", icon: "bi bi-link-45deg", description: `In the same link as the messaging system, we provide a personalized link 
                    that grants guests access to essential details about their stay. This resource includes crucial information such as check-in and check-out times, Wi-Fi passwords, and more relevant details.`}}/>
                </div>
            </div>
            <div class = "d-flex justify-content-center flex-lg-row flex-column flex-wrap">
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "iCalendar Integration", icon: "bi bi-calendar-check", description: `Already listing your accommodations on another platform? 
                    No worries! Our two-way iCalendar synchronization has you covered. Effortlessly sync your bookings, streamlining your operations and ensuring a seamless, hassle-free experience.`}}/>
                </div>
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "Custom Website", icon: "bi bi-laptop", description: `We will design and create you a custom website to showcase your 
                    business, which can be managed through our easy to use panel.`}}/>
                </div>
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "Website Migration", icon: "bi bi-arrows-move", description: `If you currently have a website, you can seamlessly transition to 
                    our system by sharing your website files with us. We'll handle the migration process, ensuring that your site smoothly integrates with our platform and maintains its full functionality.`}}/>
                </div>
            </div>
            <div class = "d-flex justify-content-center flex-lg-row flex-column flex-wrap">
                <div class = "col-12 col-lg-3 align-self-start">
                    <Feature feature={{name: "Custom Features", icon: "bi bi-gear", description: `Looking for more? We've got you covered! Our custom features are designed to meet your unique needs, providing solutions tailored to your requirements. 
                    Just let us know what you're looking for, and we'll ensure your system works exactly the way you need it to.`}}/>
                </div>
            </div>
        </div>
    )
}

export default Features;