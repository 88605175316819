import { useState, useEffect } from 'react';
import '../CSS/Seperator.scss';


const Seperator = props => { 

    return (
        <div className="Seperator mt-5 mb-5">
            <h2 className="text-center name">{props.name}</h2>
            <div className="seperator-line"><hr/></div>
        </div>
    )
}

export default Seperator;