import { useRef, useState,  } from 'react';
import '../CSS/ContactForm.scss';
import emailjs from '@emailjs/browser';

const ContactForm = props => { 


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');



    const form = useRef();

    const validateForm = (e) => {
        e.preventDefault();

        if (name === '' || email === '' || message === '') {
            document.getElementById('error').innerHTML = "Please fill out all fields.";
            return;
        } 

        if(email.indexOf('@') === -1) {
            document.getElementById('email-error').innerHTML = "Please enter a valid email address.";
            return;
        }

        if(message.length < 10) {
            document.getElementById('message-error').innerHTML = "Please enter a message with at least 10 characters.";
            return;
        }


        sendEmail(e);

    };



    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_zxqfmqp', 'template_n397vaf', form.current, 'sr3t6oQlcgQOc4Ziv')
            .then((result) => {
                //replace html in the form with a success message
                form.current.innerHTML = "<h3 class = 'text-center'>Thank you for your message! We will get back to you as soon as possible.</h3>";
            }, (error) => {
                console.log(error.text);
            });
    };
    
    return (
        <div className="ContactForm mt-5 mb-5" id="contact">
            <div class = "d-flex justify-content-center align-items-center">
                <div class = "col-7 col-lg-4">
                    <form ref={form} onSubmit={validateForm}>
                        <span class = "text-danger" id = "error"></span>
                        <div class = "form-group mb-3">
                            <input type="text" class="form-control" id="name" name="name" placeholder="Name" onChange={e => setName(e.target.value)} required/>
                        </div>
                        <span class = "text-danger" id = "email-error"></span>
                        <div class = "form-group mb-3">
                            <input type="email" class="form-control" id="email" name = "email" placeholder="Email" onChange={e => setEmail(e.target.value)} required/>
                        </div>
                        <span class = "text-danger" id = "message-error"></span>
                        <div class = "form-group mb-3">
                            <textarea class="form-control" id="message" name="message" rows="6" placeholder="Message" onChange={e => setMessage(e.target.value)} required></textarea>
                        </div>
                        <button type="submit" class="btn btn-custom">Submit</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ContactForm;